
import React, { useEffect, useState, lazy, Suspense } from "react";
import { format, parseISO } from "date-fns";
import axios from "axios";
import "@fontsource/poppins";
import "./App.css";
import { PressReleaseComponent } from "press-release";
import BannerCarousel from './components/BannerCarousel';
//import { ProductsHeader } from 'genericproductsheader';
const ProductsHeader = lazy(() => import("./components/Menubar/ProductsHeader.js"));
const Navbar = lazy(() => import("../src/components/HeaderNav"));
const Header = lazy(() => import("./components/Header"));
const Highlights = lazy(() => import("./components/Highlights"));
const Footer = lazy(() => import("./components/FooterNew/index.js"));
const LogoSection = lazy(() => import("./components/Logo Section/Logo"));
// const LoanCalculator = lazy(() => import("./components/LoanCalculator"));

function App() {
  const imgCDNURL = process.env.REACT_APP_203HLOAN_CDN_URL;
  const [themeId, setThemeId] = useState("fha203h-pricing");
  const [configJSON, setConfigJSON] = useState("");
  const [fha30, setFHA30] = useState("");
  const [pricingAsOfDate, setPricingAsOfDate] = useState("");

  async function locationError(error) {
    // Get the default price scenario
    await getPrice();
  }

  /**
   * @description Get the pricing config file details
   * 
   */
   const getConfigData = async () => {
    try {
      // Get the va-constructionsingleclosingstandard-config.json file from S3 bucket
      const configData = await axios(
        `${process.env.REACT_APP_RATE_PRODUCT}/203hfha-config.json`
      );

      //console.log(JSON.stringify(configData.data[0]));
      if (configData.data[0].FHA_30_YR_FIXED) {
        setConfigJSON(configData.data[0].FHA_30_YR_FIXED);
      }
    } catch (error) {
      console.error("Unable to load 203hfha-config.json file from S3 bucket: ", error);
    }
  };

  /**
   * @description Get the static pricing data from s3.
   * 
   */
   const getPrice = async (userStateCode) => {
    try {
      // Get the static data from s3 for the filtered state else return the default data
      // Get the state list
      const stateList = await axios(
        `${process.env.REACT_APP_RATE_PRODUCT}/state.json`
      );
      // Filter the state which has the county and zipcode
      const propertyStateData = stateList.data.find(
        (d) => d.state.code === userStateCode
      );
      //console.log("propertyStateData: " + JSON.stringify(propertyStateData));
      let response;
      if (propertyStateData) {
        const county = propertyStateData.state.county.code;
        const zipcode = propertyStateData.state.county.zipcode;
        // console.log("county: " + county);
        // console.log("zipcode: " + zipcode);
        // console.log("themeId: " + themeId);
        response = await axios(
          `${
            process.env.REACT_APP_RATE_PRODUCT
          }/${themeId}/${userStateCode.toLowerCase()}/${county}/${zipcode}/products.json`
        ).catch((error) => {
          console.error("Unable to pull pricing for user state: ", error);
          locationError();
        });
      } else {
        response = await axios(
          `${process.env.REACT_APP_RATE_PRODUCT}/${themeId}/default.json`
        );
      }
      // console.log("response: " + JSON.stringify(response.data));
      const parseValue = parseISO(response.data.createdAt);
      const dateTime = format(parseValue, "MM/dd/yyyy hh:mm a");
      setPricingAsOfDate(dateTime);

      
      
      if (response.data.FHA_30_YR_FIXED) {
        setFHA30(response.data.FHA_30_YR_FIXED);
      }
    } catch (error) {
      console.error("Unable to load todays rate: ", error);
    }
  };

  useEffect(() => {
    let userIP = "";
    let userZipcode = "";
    let userState = "";

    const fetchData = async () => {
      await axios
        .get("https://api.ipify.org", { timeout: 5000 })
        .then((response) => {
          userIP = response.data;
          getConfigData();
          //console.log("userIP: " + userIP);
          if (userIP) {
            axios
            .post(
              process.env.REACT_APP_MAXMIND,
              { ip: userIP },
              {
                headers: { "Content-Type": "application/json" },
              },
              { timeout: 5000 }
            )
            .then((response) => {
              //console.log("response.data: " + JSON.stringify(response.data));
              if (
                response.data !== "IP Not Available" &&
                response.data !== "Invalid Country"
              ) {
                userZipcode = response.data.zipcode;
                //console.log("userZipcode: " + userZipcode);
                if (userZipcode) {
                  axios
                    .get(
                      process.env.REACT_APP_SUNSOFT_RESTSERVER_ZIPCODE + userZipcode,
                      {
                        headers: { "Content-Type": "application/json" },
                      },
                      { timeout: 5000 }
                    )
                    .then((response) => {
                      userState = response.data.state;
                      //console.log("userState: " + userState);
                      // get the price scenario
                      getPrice(userState);
                    })
                    .catch((error) => {
                      console.error("Get ZIPCODE Error: ", error);
                      locationError();
                    });
                } else {
                  locationError();
                }
              } else {
                locationError();
              }
            })
            .catch((error) => {
              console.error("Post MAXMIND Error: ", error);
              locationError(error);
            });
          }
        })
        .catch((error) => {
          console.error("Get ipify Error: ", error);
          locationError(error);
        });
    };

    fetchData();
  }, []);
  return (
    <>
      <div className="bodyContainer mb-5">
        <img
          src={imgCDNURL + "6dots-group.png"}
          className="dots-group img-fluid"
          alt="6dots-group"
        />
        <img
          src={imgCDNURL + "desktop-background.webp"}
          className="desktop-bg img-fluid"
          alt="desktop-bg"
        />
        <img
          src={imgCDNURL + "mobile-background.webp"}
          className="mobile-bg img-fluid"
          alt="mobile-bg"
        />
        <Suspense fallback={<div />}>
          <Navbar />
        </Suspense>
        {/* <Suspense fallback={<div />}>
          <ProductsHeader />
        </Suspense> */}
        <Suspense fallback={<div />}>
          <Header configJSON={configJSON}  fha30={fha30} pricingAsOfDate={pricingAsOfDate}/>
        </Suspense>
        <Suspense fallback={<div />}>
        <LogoSection/>
      </Suspense>
      </div>
      <Suspense fallback={<div />}>
        <Highlights />
      </Suspense>
      {/* <div id="contact-us">
        <div className="iframeContainer">
          <iframe
            src="https://staging.swmc.com/contact-us"
            scrolling="no"
            className="iframeContent"
            loading="lazy"
          ></iframe>
        </div>
      </div> */}
      <Suspense fallback={<div />}>
      <div className="news pt-4">
        <PressReleaseComponent />
      </div>
      </Suspense>
      <Suspense fallback={<div />}>
        <BannerCarousel title="Video Testimonials" />
      </Suspense>

      {/* <Suspense fallback={<div />}>
        {fha30 ? <LoanCalculator offerRate={fha30.rate} offerTerm={360} /> : ""}
      </Suspense> */}
      <Suspense fallback={<div />}>
        <Footer />
      </Suspense>
    </>
  );
}

export default App;
